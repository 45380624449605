import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import FeaturesWithImages from "../components/features_with_images"
import Benefits from "../components/benefits"
import SignupBanner from "../components/signupBanner"
import CargoCrewTestiomnial from "../components/testimonials/ebbo_ schopnie"

import { graphql } from "gatsby" // to query for image data

const BreakbulkPage = ({ data }) => (
  <Layout>
    <SEO
      title="Breakbulk Vessel loading software"
      description="Create 3D load plans for coasters and barges. Palletize. Manual Edits. Step-by-step instructions. Start your free trial today!"
    />
    <Hero
      image={data.heroImage}
      title="Breakbulk Vessels"
      body="Create interactive load plans for coasters and barges."
      className="bg-white"
    />
    <CargoCrewTestiomnial className="bg-primary text-white" />

    <FeaturesWithImages
      features={[
        {
          title: "Tween Decks",
          image: data.twd,
          content: (
            <span>
              Add tween decks to your vessels and utilize those when loading
              cargoes.
            </span>
          ),
        },
        {
          title: "Create your custom",
          image: data.customVessel,
          content: (
            <span>
              You can create your own vessels with up to four holds, tweendecks
              and benches. The vessel shape will be changed according to the
              size of the vessel.
            </span>
          ),
        },
        {
          title: "Barges",
          image: data.barge,
          content: (
            <span>
              Create Barges, like this one with one hold and set a max loading
              height to be sure the height restrictions will not be exceeded
            </span>
          ),
        },
      ]}
    ></FeaturesWithImages>
    <Benefits></Benefits>
    <SignupBanner></SignupBanner>
  </Layout>
)

export const query = graphql`
  {
    heroImage: file(
      relativePath: { eq: "containers/vessel-with-cargoes.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    twd: file(relativePath: { eq: "containers/vessel-with-tween-decks.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    customVessel: file(
      relativePath: { eq: "screens/create-vessels-with-container-builder.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    barge: file(relativePath: { eq: "containers/barge-view-from-top.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

export default BreakbulkPage
