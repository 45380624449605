import React from "react"

import Testimonial from "./testimonial"
import { useStaticQuery, graphql } from "gatsby" // to query for image data

const T = props => {
  const image = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "profiles/ebbo_schopnie.jpeg" }) {
        base
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <Testimonial
      by="Philipp-Christopher Ebbo Schopnie, Managing Director,
      CargoCrew International GmbH"
      text="Planning and coordination from the start to the end of the project!
      Freight here. Freight now.
      
      In order to determine the required shipping space and/or container requirements for our projects as precisely as possible in advance,
      we have been successfully using Cargo-Planner for many years."
      image={image}
      {...props}
    ></Testimonial>
  )
}

export default T
